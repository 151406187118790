import { AbstractConnector } from '@web3-react/abstract-connector';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { darken } from 'polished';
import React, { ReactNode, useMemo } from 'react';
import { Activity } from 'react-feather';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import CoinbaseWalletIcon from '../../assets/images/coinbaseWalletIcon.svg';
import FortmaticIcon from '../../assets/images/fortmaticIcon.png';
import PortisIcon from '../../assets/images/portisIcon.png';
import WalletConnectIcon from '../../assets/images/walletConnectIcon.svg';
import {
  fortmatic,
  injected,
  portis,
  walletconnect,
  walletlink
} from '../../connectors';
import { NetworkContextName } from '../../constants';
import useENSName from '../../hooks/useENSName';
import { useHasSocks } from '../../hooks/useSocksBalance';
import { useWalletModalToggle } from '../../state/application/hooks';
import {
  isTransactionRecent,
  useAllTransactions
} from '../../state/transactions/hooks';
import { TransactionDetails } from '../../state/transactions/reducer';
import { ButtonSecondary } from '../Button';

import Identicon from '../Identicon';
import Loader from '../Loader';

import { RowBetween } from '../Row';
import WalletModal from '../WalletModal';

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > * {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`;

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  user-select: none;
  :focus {
    outline: none;
  }
`;

const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
`;

const StyledWeb3StatusConnect = styled(Web3StatusGeneric)<{ faded?: boolean }>`
  color: ${({ theme }) => theme.primaryText1};
  font-weight: 500;

  ${({ faded }) =>
    faded &&
    css`
      color: ${({ theme }) => theme.primaryText1};

      :hover,
      :focus {
        border: 1px solid ${({ theme }) => darken(0.05, theme.primary4)};
        color: ${({ theme }) => darken(0.05, theme.primaryText1)};
      }
    `}
`;

const Web3StatusConnect = (props: {
  children: ReactNode;
  id: string;
  onClick: (...args: any[]) => void;
  faded: boolean | undefined;
  style?: any;
}) => {
  const { children, id, faded, onClick, style } = props;
  return (
    <StyledWeb3StatusConnect id={id} faded={faded} onClick={onClick} style={style}>
      {children}
    </StyledWeb3StatusConnect>
  );
};

const StyledWeb3StatusConnected = styled(Web3StatusGeneric)<{
  pending?: number;
}>`
  background: none;
  color: ${({ pending, theme }) => (pending === 1 ? theme.white : theme.text1)};
  font-weight: 500;
  padding: 0;
  align-items: center;
  width: max-content;
  justify-content: start;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const Web3StatusConnected = (props: {
  id: string;
  children: ReactNode;
  onClick: (...args: any[]) => void;
  pending: number;
}) => {
  const { id, children, onClick, pending } = props;
  return (
    <StyledWeb3StatusConnected id={id} onClick={onClick} pending={pending}>
      {children}
    </StyledWeb3StatusConnected>
  );
};

const Text = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  font-size: 16px;
  width: fit-content;
  font-weight: 500;
`;

const NetworkIcon = styled(Activity)`
  width: 18px;
  height: 18px;
`;

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime;
}

const SOCK = (
  <span
    role='img'
    aria-label='has socks emoji'
    style={{ marginTop: -4, marginBottom: -4 }}
  >
    🧦
  </span>
);

// eslint-disable-next-line react/prop-types
function StatusIcon({ connector }: { connector: AbstractConnector }) {
  if (connector === injected) {
    return <Identicon />;
  } else if (connector === walletconnect) {
    return (
      <IconWrapper size={18}>
        <img src={WalletConnectIcon} alt={''} />
      </IconWrapper>
    );
  } else if (connector === walletlink) {
    return (
      <IconWrapper size={18}>
        <img src={CoinbaseWalletIcon} alt={''} />
      </IconWrapper>
    );
  } else if (connector === fortmatic) {
    return (
      <IconWrapper size={18}>
        <img src={FortmaticIcon} alt={''} />
      </IconWrapper>
    );
  } else if (connector === portis) {
    return (
      <IconWrapper size={18}>
        <img src={PortisIcon} alt={''} />
      </IconWrapper>
    );
  }
  return null;
}

function Web3StatusInner() {
  const { t } = useTranslation();
  const { account, connector, error } = useWeb3React();

  const { ENSName } = useENSName(account ?? undefined);

  const allTransactions = useAllTransactions();

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions);
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
  }, [allTransactions]);

  const pending = sortedRecentTransactions
    .filter(tx => !tx.receipt)
    .map(tx => tx.hash);

  const hasPendingTransactions = !!pending.length;
  const hasSocks = useHasSocks();
  const toggleWalletModal = useWalletModalToggle();

  if (account) {
    return (
      <Web3StatusConnected
        id='web3-status-connected'
        onClick={toggleWalletModal}
        pending={hasPendingTransactions ? 1 : 0}
      >
        {!hasPendingTransactions && connector && (
          <StatusIcon connector={connector} />
        )}
        {hasPendingTransactions ? (
          <RowBetween>
            <Text>{pending?.length} Pending</Text> <Loader stroke='white' />
          </RowBetween>
        ) : (
          <>
            {hasSocks ? SOCK : null}
            <Text>{ENSName || account}</Text>
          </>
        )}
      </Web3StatusConnected>
    );
  } else if (error) {
    return (
      <Web3StatusError onClick={toggleWalletModal}>
        <NetworkIcon />
        <Text>
          {error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error'}
        </Text>
      </Web3StatusError>
    );
  } else {
    return (
      <Web3StatusConnect
        id='connect-wallet'
        onClick={toggleWalletModal}
        faded={!!account || undefined}
        style={{ gap: "8px", justifyContent: "start" }}
      >
        <Identicon />
        <Text style={{color:"white"}}>{t('No wallet connected. ')}</Text>
        <Text>{t('Connect wallet')}</Text>
      </Web3StatusConnect>
    );
  }
}

export default function Web3Status() {
  const { active, account } = useWeb3React();
  const contextNetwork = useWeb3React(NetworkContextName);

  const { ENSName } = useENSName(account ?? undefined);

  const allTransactions = useAllTransactions();

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions);
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
  }, [allTransactions]);

  const pending = sortedRecentTransactions
    .filter(tx => !tx.receipt)
    .map(tx => tx.hash);
  const confirmed = sortedRecentTransactions
    .filter(tx => tx.receipt)
    .map(tx => tx.hash);

  if (!contextNetwork.active && !active) {
    return null;
  }

  return (
    <>
      <Web3StatusInner />
      <WalletModal
        ENSName={ENSName ?? undefined}
        pendingTransactions={pending}
        confirmedTransactions={confirmed}
      />
    </>
  );
}
