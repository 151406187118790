import { ChainId } from '@uniswap/sdk';
import React, { ReactNode } from 'react';
import { isMobile } from 'react-device-detect';
import { Text } from 'rebass';

import styled from 'styled-components';

//import Logo from '../../assets/svg/logo.svg'
//import LogoDark from '../../assets/svg/logo_white.svg'
//import Wordmark from '../../assets/svg/wordmark.svg'
//import WordmarkDark from '../../assets/svg/wordmark_white.svg'
import { useActiveWeb3React } from '../../hooks';
//import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks';

import { WhiteCard } from '../Card';
//import Settings from '../Settings'
//import Menu from '../Menu'

//import Row, { RowBetween } from '../Row'
import Web3Status from '../Web3Status';
import { CSSProperties } from '@mui/styles';
//import VersionSwitch from './VersionSwitch'

// const HeaderFrame = styled.div`
//   z-index: 2;
// `;

// const HeaderElement = styled.div`
//   display: flex;
//   align-items: center;
// `;

// const HeaderElementWrap = styled.div`
//   display: flex;
//   align-items: center;

//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     margin-top: 0.5rem;
// `};
// `;

/*
const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }
`

const TitleText = styled(Row)`
  width: fit-content;
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`
*/

const StyledAccountElement = styled.div<{ active?: number }>`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  white-space: nowrap;

  :focus {
    border: 1px solid blue;
  }
`;

const AccountElement = (props: {
  children: ReactNode;
  className?: string;
  active: number;
  style: CSSProperties;
}) => {
  const { children, className, active, style } = props;
  return (
    <StyledAccountElement className={className} active={active} style={style}>
      {children}
    </StyledAccountElement>
  );
};

const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  pointer-events: auto;
`;

const NetworkCard = styled(WhiteCard)`
  width: fit-content;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 12px;
  padding: 8px 16px;
  font-size: 1rem;
`;

/*
const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    img {
      width: 4.5rem;
    }
  `};
`
*/

// const HeaderControls = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;

//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     flex-direction: column;
//     align-items: flex-end;
//   `};
// `;

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`;

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: null,
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan'
};

export default function Header({ classes }: { classes?: any }) {
  const { account, chainId } = useActiveWeb3React();

  const userEthBalance = useETHBalances(account ? [account] : [])?.[
    account ?? ''
  ];
  //const [isDark] = useDarkModeManager()

  /*
        <HeaderElement>
          <Title href=".">
            <UniIcon>
              <img src={isDark ? LogoDark : Logo} alt="logo" />
            </UniIcon>
            <TitleText>
              <img style={{ marginLeft: '4px', marginTop: '4px' }} src={isDark ? WordmarkDark : Wordmark} alt="logo" />
            </TitleText>
          </Title>
        </HeaderElement>
*/

  return (
    <div className={classes?.root}>
      <TestnetWrapper>
        {!isMobile && chainId && NETWORK_LABELS[chainId] && (
          <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>
        )}
      </TestnetWrapper>
      <AccountElement
        active={account ? 1 : 0}
        style={{ pointerEvents: 'auto' }}
      >
        {account && userEthBalance ? (
          <BalanceText
            style={{ flexShrink: 0 }}
            pl='0.75rem'
            pr='0.5rem'
            fontWeight={500}
          >
            {userEthBalance?.toSignificant(4)} ETH
          </BalanceText>
        ) : null}
        <Web3Status />
      </AccountElement>
    </div>
  );
}
/*
          <HeaderElementWrap>
            <VersionSwitch />
            <Settings />
            <Menu />
          </HeaderElementWrap>
*/

export const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`;
