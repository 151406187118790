import React, { PureComponent } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SnackBar, SwapWrapper, Nav } from 'components';
import About from 'pages/about';
import ToS from 'pages/tos';
import Staking from 'pages/staking';
import theme from 'theme/oxen';
// from uniswap
import { Provider } from 'react-redux';
import store from './state';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import { NetworkContextName } from './constants';
import getLibrary from './utils/getLibrary';

import StyledThemeProvider, {
  FixedGlobalStyle,
  ThemedGlobalStyle
} from './theme';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Web3ReactManager from 'components/Web3ReactManager';

/*
import ApplicationUpdater from './state/application/updater'
import ListsUpdater from './state/lists/updater'
import MulticallUpdater from './state/multicall/updater'
import UserUpdater from './state/user/updater'
*/
import TransactionUpdater from './state/transactions/updater';
import { SnackbarVariant } from 'components/SnackBar';

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

/*
if ('ethereum' in window) {
  ;(window.ethereum as any).autoRefreshOnNetworkChange = false
}
      <ListsUpdater />
      <UserUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
*/

function Updaters() {
  return (
    <>
      <TransactionUpdater />
    </>
  );
}

export default class App extends PureComponent {
  state = {
    snackbar: {
      message: null,
      variant: 'success' as SnackbarVariant,
      open: false
    }
  };

  showMessage = (message: any, variant: any) => {
    const snackbar = {
      message,
      variant: variant || 'error',
      open: true
    };
    this.setState({ snackbar });
  };

  closeMessage = (event: any, reason: any) => {
    if (reason === 'clickaway') return;
    const snackbar = {
      ...this.state.snackbar,
      open: false
    };
    this.setState({ snackbar });
  };

  renderSnackbar = () => {
    const { snackbar } = this.state;
    return (
      <SnackBar
        message={snackbar.message}
        open={snackbar.open}
        onClose={this.closeMessage}
        variant={snackbar.variant}
      />
    );
  };

  renderBackgroundImage = () => {
    return <div id='background'></div>;
  };

  render() {
    return (
      <HashRouter>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Web3ProviderNetwork getLibrary={getLibrary}>
            <Provider store={store}>
              <FixedGlobalStyle />
              <Updaters />
              <StyledThemeProvider>
                <ThemedGlobalStyle />
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  {this.renderBackgroundImage()}
                  <Web3ReactManager>
                    <div id='content'>
                      <Nav />
                      <Switch>
                        <Route
                          exact
                          strict
                          path='/swap'
                          component={SwapWrapper}
                        />
                        <Route exact strict path='/about' component={About} />
                        <Route exact strict path='/tos' component={ToS} />
                        <Route
                          exact
                          strict
                          path='/staking'
                          component={Staking}
                        />
                        <Route component={SwapWrapper} />
                      </Switch>
                      <p></p>
                    </div>
                  </Web3ReactManager>
                </ThemeProvider>
              </StyledThemeProvider>
            </Provider>
          </Web3ProviderNetwork>
        </Web3ReactProvider>
      </HashRouter>
    );
  }
}
