// export const flexCenter = {
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center'
// };

// NOTE: This changes the main page section padding
export const section = {
  borderRadius: '8px',
  background: 'rgba(255, 255, 255, 0.05)',
  backgroundBlendMode: 'overlay',
  boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.25)',
  padding: '24px'
};
