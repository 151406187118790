import Box from '@mui/material/Box';
import React from 'react';
import { ImageLoader } from 'components';
// eslint-disable-next-line
import LazyLoad from 'react-lazy-load';
import { NavLink } from 'react-router-dom';

export default function TitleImage() {
  return (
    <Box display='flex' justifyContent='center'>
      <NavLink to='/'>
        <LazyLoad height={'40px'} className='titleContainer'>
          <ImageLoader
            className='titleImage'
            loadedClassName='titleImageLoaded'
            src='/images/logo.png'
            alt='Logo'
            width='80'
            height='40'
          />
        </LazyLoad>
      </NavLink>
    </Box>
  );
}
