import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { OxenButton } from 'components';
import TitleImage from '../TitleImage';
import Button from '@mui/material/Button';

export default function Nav() {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div
      style={{
        width: '100dvw',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '32px 32px'
      }}
    >
      <TitleImage />
      <Button onClick={handleClick}>|||</Button>
      <Grid
        container
        justifyContent='end'
        alignItems='end'
        className='links'
        position='fixed'
        flexDirection='column'
        top='96px'
        right='0'
        width='max-content'
        zIndex={999}
        display={open ? 'flex' : 'none'}
        style={{
          backdropFilter: 'blur(10px)'
        }}
      >
        <NavLink to='/about'>
          <OxenButton label='About SESH' variant='text' />
        </NavLink>
        <a href='/swap'>
          <OxenButton label='Swap' variant='text' />
        </a>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://etherscan.io/token/0xd1e2d5085b39b80c9948aeb1b9aa83af6756bcc5'
        >
          <OxenButton label={'SESH Ethereum Contract 🔗'} variant='text' />
        </a>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://docs.oxen.io/using-the-oxen-blockchain/oxen-wallet-guides'
        >
          <OxenButton label={'Legacy OXEN Wallet 🔗'} variant='text' />
        </a>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://v2.info.uniswap.org/pair/0xbaeca7c35346a8d31811ef971f38603012a12c1e'
        >
          <OxenButton label={'SESH Uniswap 🔗'} variant='text' />
        </a>
      </Grid>
    </div>
  );
}
