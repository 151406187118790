import React, { useEffect, useState, useCallback, useRef } from 'react';
import { common } from 'theme';
import { OxenTheme, colors } from 'theme/oxen';
import makeStyles from '@mui/styles/makeStyles';
import AnimateHeight from 'react-animate-height';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { OxenButton, QRIcon, Input } from 'components';
import { SWAP_TYPE } from 'utils/constants';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import QRCode from 'qrcode.react';
import { withEthereum } from '../withEtherum';
import theme from 'theme/app';
import { NavLink } from 'react-router-dom';

/**
 * Copies the specified text to the clipboard and displays a success toast message.
 *
 * @param textToCopy The text to be copied to the clipboard.
 */
async function copyToClipboard(
  textToCopy: string,
  onCopyComplete?: () => void
) {
  await navigator.clipboard.writeText(textToCopy);
  if (onCopyComplete) {
    onCopyComplete();
  }
}

const onCopy = (id: string) => {
  var elm = document.getElementById(id) as Node;
  let range;
  // for Internet Explorer
  const anyBody = document.body as any;

  if (anyBody.createTextRange) {
    range = anyBody.createTextRange();
    range.moveToElementText(elm);
    range.select();
    document.execCommand('Copy');
  } else if (window.getSelection) {
    // other browsers
    const selection = window.getSelection();
    if (selection) {
      range = document.createRange();
      range.selectNodeContents(elm);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('Copy');
    }
  }
};

const RenderQR = (props: {
  showQR: boolean;
  qrSize: number;
  classes: any;
  swapInfo: any;
}) => {
  const { showQR, qrSize, classes, swapInfo } = props;
  const { depositAddress } = swapInfo;
  const height = showQR ? 'auto' : 0;

  return (
    <AnimateHeight duration={250} height={height}>
      <Box className={classes.qrContainer}>
        <Box className={classes.qr}>
          <QRCode value={depositAddress} renderAs='svg' size={qrSize} />
        </Box>
      </Box>
    </AnimateHeight>
  );
};

const RenderMemo = (props: { classes: any; swapInfo: any }) => {
  const {
    classes,
    swapInfo: { memo }
  } = props;

  if (!memo) return null;

  return (
    <Box className={classes.memoFrame}>
      <Typography className={classes.warningText}>
        PLEASE READ CAREFULLY
      </Typography>
      <Typography id='memo' className={classes.memo}>
        {memo}
      </Typography>
      <Tooltip title='Copy Memo' placement='right'>
        <IconButton
          onClick={() => onCopy('memo')}
          aria-label='Copy Memo'
          size='large'
        >
          <FileCopyOutlined />
        </IconButton>
      </Tooltip>
      <Typography className={classes.instructionBold}>
        When creating the transaction, please paste the string above into the{' '}
        <b>Memo</b> field. <br />
        Ensure that this is the only thing that you put in the field.
      </Typography>
      <Typography className={classes.warningText}>
        If done incorrectly then you will not receive <b>OXEN</b> into your
        designated address.
      </Typography>
    </Box>
  );
};

const RenderDepositInstructions = (props: {
  swapType: string;
  classes: any;
  swapInfo: any;
  info: any;
  eth: any;
  loading: boolean;
  amount: string;
  setAmount: Function;
  minAmount: number;
  burn: Function;
  showQR: boolean;
  qrSize: number;
  toggleQR: any;
}) => {
  const {
    swapType,
    classes,
    swapInfo,
    eth,
    loading,
    amount,
    setAmount,
    minAmount,
    burn,
    showQR,
    qrSize,
    toggleQR
  } = props;

  const depAddressRef = useRef<HTMLInputElement>(null);

  const { depositAddress } = swapInfo;

  const onAmountChange = (event: any) => {
    let value = +Number(event.target.value).toFixed(9);
    // skip NaN check when containing a start/end with .
    if (event.target.value.match(/^\.|\.$/)) {
      value = event.target.value;
    } else {
      if (isNaN(value)) {
        return;
      }
    }
    // allow numbers
    setAmount(
      '' + value // convert back to string because Input requires string
    );
  };

  const onBurn = async () => {
    // warn user if amount is out of bounds
    if (isNaN(Number(amount))) {
      alert('Amount is invalid');
      return;
    }

    if (!eth.account) {
      alert(
        'Failed to get wallet information from metamask. Try reconnecting.'
      );
      return;
    }

    if (eth.balance === undefined) {
      alert('Failed to get wallet balance from metamask. Try reconnecting.');
      return;
    }

    if (!swapInfo.memo) {
      console.error('Cannot find burn memo', swapInfo);
      alert('Something went wrong');
      return;
    }

    const numberAmount = Number(amount);

    if (numberAmount <= minAmount) {
      alert('Amount must be more than ' + minAmount);
      return;
    }

    if (numberAmount > eth.balance) {
      alert('You do not have more than ' + eth.balance);
      return;
    }

    try {
      await burn(numberAmount, swapInfo.memo);
      console.log(`Burned ${numberAmount} wOxen`);
    } catch (e) {
      console.error('onBurn', e);
    }
  };

  if (swapType === SWAP_TYPE.WOXEN_TO_eSESH) {
    if (!eth.account) {
      return (
        <React.Fragment>
          <Typography className={classes.instructionBold}>
            Please connect your web3-enabled wallet
          </Typography>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Box className={classes.memoFrame}>
          <Input
            fullWidth
            value={amount}
            onChange={onAmountChange}
            label='How much wOxen do you want to transfer? '
            // TODO doesn't exist on component
            // loading={loading}
          />
          <OxenButton
            fullWidth
            label='Send'
            loading={loading}
            variant='outlined'
            onClick={async () => {
              await onBurn();
            }}
          />
        </Box>
        <Typography className={classes.instructionBold}>
          After you click Send look for a MetaMask popup to confirm the (gas
          fees for the) Send transaction.
        </Typography>
      </React.Fragment>
    );
  }

  const handleClick = async () => {
    if (depositAddress) {
      await copyToClipboard(depositAddress, () => {
        console.log('copied');
        console.log(depAddressRef.current);
        depAddressRef.current?.select();
      });
    }
  };

  return (
    <React.Fragment>
      <Input
        label='Transfer your OXEN to:'
        fullWidth
        value={depositAddress}
        multiline
        ref={depAddressRef}
        overrideEndAdornment={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              marginLeft: '4px',
            }}
          >
            <Tooltip title='Copy Address' placement='left'>
              <IconButton
                onClick={handleClick}
                aria-label='Copy Address'
                size='small'
              >
                <FileCopyOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title='Toggle QR' placement='right'>
              <IconButton
                onClick={toggleQR}
                aria-label='Toggle QR'
                size='small'
              >
                <QRIcon />
              </IconButton>
            </Tooltip>
          </div>
        }
      />
      <RenderQR
        showQR={showQR}
        qrSize={qrSize}
        classes={classes}
        swapInfo={swapInfo}
      />
      <RenderMemo classes={classes} swapInfo={swapInfo} />
    </React.Fragment>
  );
};

const RenderInstructions = (props: {
  swapType: string;
  classes: any;
  info: any;
  swapInfo: any;
  eth: any;
  loading: boolean;
  amount: string;
  setAmount: Function;
  minAmount: number;
  burn: Function;
  showQR: boolean;
  qrSize: number;
  toggleQR: Function;
}) => {
  const {
    swapType,
    classes,
    info,
    swapInfo,
    eth,
    loading,
    amount,
    setAmount,
    minAmount,
    burn,
    showQR,
    qrSize,
    toggleQR
  } = props;

  const oxenFee =
    (info && info.fees && (info.fees["oxen"] || 0) / 1e9) || 0;

  const conversionRatio = info && info.conversion && info.conversion.denominator ?
    (info.conversion.numerator / info.conversion.denominator)
    : null

  return (
    <Box className={classes.instructionContainer}>
      <Typography className={classes.instructionBold} style={{ fontSize: '16px', marginBottom: "0px" }}>
        Here's what you need to do next:
      </Typography>
      <RenderDepositInstructions
        swapType={swapType}
        classes={classes}
        swapInfo={swapInfo}
        info={info}
        eth={eth}
        loading={loading}
        amount={amount}
        setAmount={setAmount}
        minAmount={minAmount}
        burn={burn}
        showQR={showQR}
        qrSize={qrSize}
        toggleQR={toggleQR}
      />
      {conversionRatio !== null ?
        <Typography className={classes.instructionBold}>
          Conversion rate: <NavLink to='/tos'> 1 OXEN = {conversionRatio} SESH</NavLink>
        </Typography>
        : null}
      <Typography className={classes.instructions}>
        <NavLink to='/tos'>Learn more</NavLink> about the conversion rate
        between OXEN and SESH
      </Typography>
      <Typography className={classes.instructions}>
        There will be a processing fee of {oxenFee} OXEN which will be charged
        when processing all your pending swaps.
      </Typography>
      <Typography className={classes.instructionBold}>
        When you swap OXEN via the bridge, you will be sent SESH on the Arbitrum blockchain.
      </Typography>
      <Typography className={classes.instructions}>
        Large transactions may take up to 24 hours to process. If you run into
        any trouble, or your swap request has not gone through 1 hour after
        sending, please contact us:{' '}
        <a href='mailto:contact@session.foundation'>
          contact@session.foundation
        </a>
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme: OxenTheme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '700px',
      position: 'sticky',
      top: 0
    },
    ...common.section
  },
  rootNoBackground: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '500px',
      position: 'sticky',
      top: 0
    }
  },
  instructionContainer: {
    marginTop: '16px',
    flexDirection: 'column',
    wordBreak: 'break-word'
  },
  instructions: {
    fontSize: '0.9rem',
    textAlign: 'left',
    marginBottom: '16px'
  },
  instructionBold: {
    color: colors.white,
    fontSize: '0.9rem',
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: '16px',
    overflowWrap: 'break-word'
  },
  memoFrame: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  memo: {
    border: '1px solid',
    borderColor: colors.oxenBlack90,
    borderRadius: '3px',
    backgroundColor: colors.oxenBlack90,
    color: 'white',
    padding: '1rem',
    overflowWrap: 'break-word',
    maxWidth: '100%',
    textAlign: 'left'
  },
  warningText: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(1, 0),
    textAlign: 'left'
  },
  link: {
    cursor: 'pointer',
    a: {
      color: colors.oxenBlack90,
      fontWeight: 500
    }
  },
  qr: {
    padding: theme.spacing(1),
    backgroundColor: 'rgba(0,0,0,0)',
    '& svg > path:first-child': {
      fill: 'none'
    },
    '& svg > path': {
      fill: 'white'
    }
  },
  qrContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  }
}));

type SwapInfoProps = {
  swapType: string;
  swapInfo: any;
  info: any;
  onRefresh: (...args: any[]) => any;
  onBack: (...args: any[]) => any;
  loading: boolean;
  eth: any;
  burn: Function;
};

let timer: any;

function SwapInfo(props: SwapInfoProps) {
  const {
    swapType,
    swapInfo,
    info,
    onRefresh,
    onBack,
    loading,
    eth,
    burn
  } = props;
  const [showQR, setShowQR] = useState(false);
  const [qrSize, setQRSize] = useState(128);
  const [amount, setAmount] = useState('0');
  const [minAmount, setMinAmount] = useState(0);

  const classes = useStyles(theme);

  const updateFee = useCallback(() => {
    const oxenFee = (info && info.fees && info.fees.oxen / 1e9) || 0;
    setMinAmount(oxenFee);
  }, [info]);

  const onResize = () => {
    const width = window.innerWidth;
    const newSize = width <= 600 ? 128 : 210;
    setQRSize(newSize);
  };

  const toggleQR = () => {
    setShowQR(!showQR);
  };

  useEffect(() => {
    updateFee();

    // Run a timer every 10 seconds to refresh
    timer = setInterval(onRefresh, 10 * 1000);

    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      clearInterval(timer);
      window.removeEventListener('resize', onResize);
    };
  }, [onRefresh, updateFee]);

  useEffect(() => {
    // TODO need to check if info is different
    // if (!isEqual(prevProps.info, this.props.info)) {
    //   this.updateFee();
    // }
  }, []);

  return (
    <div className={classes.rootNoBackground}>
      {/* TODO Confirm we want left alignment but relative to what? */}
      <Grid item xs={12} alignItems='flex-start'>
        <Typography>
          <Link className={classes.link} onClick={onBack}>
            &lt; Back
          </Link>
        </Typography>
      </Grid>
      <RenderInstructions
        swapType={swapType}
        classes={classes}
        swapInfo={swapInfo}
        info={info}
        eth={eth}
        loading={loading}
        amount={amount}
        setAmount={setAmount}
        minAmount={minAmount}
        burn={burn}
        showQR={showQR}
        qrSize={qrSize}
        toggleQR={toggleQR}
      />
    </div>
  );
}

const EthSwapInfo = withEthereum(SwapInfo);
export default EthSwapInfo;
