import React, { useEffect, useState, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Warning } from 'utils/error';
import { store, dispatcher, Actions, Events } from 'store';
import { SWAP_TYPE, TYPE } from 'utils/constants';
import {
  SwapSelection,
  SwapInfo,
  SwapList,
  Header
} from 'components';
import { common } from 'theme';
import theme, { OxenTheme } from 'theme/oxen';
import { NavLink } from 'react-router-dom';
import StyledLabel from '../label';
import Button from '@mui/material/Button';
import RefreshIcon from "../refreshIcon";

const currencySymbols = {
  [TYPE.aSESH]: 'SESH',
  [TYPE.eSESH]: 'SESH'
};

let isIdle = false;
let idleReference: Function;

function checkIdle(idleTime: number) {
  let time: NodeJS.Timeout;
  window.onload = resetTimer;
  // DOM Events
  document.onmousemove = resetTimer;
  document.onkeypress = resetTimer;

  function resetTimer() {
    isIdle = false;
    clearTimeout(time);
    time = setTimeout(() => {
      isIdle = true;
      console.log('Idle detected.');
    }, idleTime);
  }

  return () => {
    clearTimeout(time);
    window.onload = () => {};
    document.onmousemove = () => {};
    document.onkeypress = () => {};
  };
}

const RenderReceivingAmount = (props: {
  swaps: Array<any>;
  swapType: string;
  info: any;
  classes: any;
}) => {
  const { swaps, info, classes } = props;
  if (!swaps) return null;

  const pendingSwaps = swaps.filter(
    s => s.transferTxHashes && s.transferTxHashes.length === 0
  );
  const total = pendingSwaps.reduce(
    (total, swap) => total + parseFloat(swap.amount),
    0
  );


  const { fees } = info;
  const fee = (fees && fees['oxen']) || 0;
  const pendingTotal = Math.max(0, total - fee) / 1e9;
  const formattedPendingTotal = pendingTotal.toLocaleString('en-US', {
    maximumFractionDigits: 9,
    minimumFractionDigits: 0
  });

  return (
    <Box display='flex' flexDirection='row' alignItems='center'>
      <Typography className={classes.statTitle}>Amount Due:</Typography>
      <Typography className={classes.statAmount}>
        {formattedPendingTotal} {currencySymbols['aSESH']}
      </Typography>
    </Box>
  );
};

const RenderTransactions = (props: {
  swaps: Array<any>;
  unconfirmed: Array<any>;
  swapType: string;
  info: any;
  classes: any;
  onRefresh: (...args: any[]) => any;
  manualEthAddress: string;
  loading: boolean;
}) => {
  const {
    swaps,
    unconfirmed,
    swapType,
    info,
    classes,
    loading,
    manualEthAddress,
    onRefresh
  } = props;

  const unconfirmedSwaps = unconfirmed.map(({ hash, amount, created }) => ({
    uuid: hash,
    type: swapType,
    amount,
    txHash: hash,
    transferTxHashes: [],
    created,
    unconfirmed: true
  }));

  const merged = [...unconfirmedSwaps, ...swaps];


  useEffect(() => {
    onRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- on mount
  }, []);

  return (
    <Grid item xs={12} md={6}>
      <Box display='flex' flexDirection='column' className={classes.section}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            marginBottom: '16px'
          }}
        >
          <StyledLabel label={manualEthAddress.length ? 'Entered Swap Address' : 'Connected Address'} />
          {manualEthAddress.length ?
            <Typography>
              {manualEthAddress}
            </Typography>
            : <Header />}
        </div>
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          className={classes.xsColumn}
        >
          <StyledLabel label='Transactions' style={{ marginBottom: '0.5rem' }} />
        </Box>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            borderRadius: '8px'
          }}
          className={classes.darkSection}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderRadius: '8px 8px 0px 0px',
              padding: '4px 8px'
            }}
            className={classes.darkSection}
          >
            <RenderReceivingAmount
              swaps={swaps}
              swapType={swapType}
              info={info}
              classes={classes}
            />
            <Button disabled={loading} onClick={onRefresh} style={{minWidth: '0px'}}>
              <RefreshIcon />
            </Button>
          </div>
          <Grid item xs={12}>
            <SwapList swaps={merged} />
          </Grid>
        </div>
      </Box>
      <div className='header-wrapper'>
        <NavLink to='/tos'>Terms of Service</NavLink>
      </div>
    </Grid>
  );
};

const RenderSelection = (props: {
  loading: boolean;
  swaps: Array<any>;
  unconfirmed: Array<any>;
  swapType: string;
  setSwapType: Function;
  onNext: (...args: any[]) => void;
  setManualEthAddress: (address: string) => void;
  classes: any;
}) => {
  const {
    loading,
    swaps,
    unconfirmed,
    swapType,
    setSwapType,
    onNext: _onNext,
    setManualEthAddress,
    classes
  } = props;

  return (
    <Grid item xs={12} className={classes.item}>
      <SwapSelection
        swaps={swaps}
        unconfirmed={unconfirmed}
        swapType={swapType}
        setManualEthAddress={setManualEthAddress}
        onSwapTypeChanged={(_swapType: any) => setSwapType(_swapType)}
        onNext={(address: string) => {
          _onNext(address);
        }}
        loading={loading}
      />
      <div className='header-wrapper'>
        <NavLink to='/tos'>Terms of Service</NavLink>
      </div>
    </Grid>
  );
};

const RenderInfo = (props: {
  loading: boolean;
  swaps: Array<any>;
  unconfirmed: Array<any>;
  swapType: string;
  swapInfo: SwapInfoType;
  info: any;
  onRefresh: (...args: any[]) => any;
  manualEthAddress: string;
  resetState: Function;
  classes: any;
}) => {
  const {
    loading,
    swaps,
    unconfirmed,
    swapType,
    swapInfo,
    info,
    onRefresh,
    manualEthAddress,
    resetState,
    classes
  } = props;

  return (
    <React.Fragment>
      <RenderTransactions
        swaps={swaps}
        unconfirmed={unconfirmed}
        swapType={swapType}
        info={info}
        classes={classes}
        onRefresh={onRefresh}
        manualEthAddress={manualEthAddress}
        loading={loading}
      />
      <Grid item xs={12} md={6} className={classes.item}>
        <SwapInfo
          swapType={swapType}
          swapInfo={swapInfo}
          info={info}
          onRefresh={onRefresh}
          onBack={resetState}
          loading={loading}
        />
      </Grid>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: OxenTheme) => ({
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  itemColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  spaceBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  statTitle: {
    marginLeft: '6px',
    marginRight: '4px',
    fontSize: '0.84rem'
  },
  statAmount: {
    fontWeight: 600,
    fontSize: '0.94rem'
  },
  section: {
    ...common.section,
    [theme.breakpoints.up('md')]: {
      maxWidth: '800px',
      margin: 'auto'
    }
  },
  darkSection: {
    background: 'rgba(0, 0, 0, 0.2)',
    backgroundBlendMode: 'overlay',
    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.25) inset'
  },
  xsColumn: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  }
}));

type SwapInfoType = { uuid: string };

type SwapProps = {
  showMessage: Function;
};

export default function Swap(props: SwapProps) {
  const { showMessage } = props;
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [swapType, setSwapType] = useState(SWAP_TYPE.OXEN_TO_aSESH);
  const [info, setInfo] = useState({});
  const [swapInfo, setSwapInfo] = useState<SwapInfoType | null>();
  const [swaps, setSwaps] = useState([]);
  const [unconfirmed, setUnconfirmed] = useState([]);
  const [manualEthAddress, setManualEthAddress] = useState('');

  const classes = useStyles(theme);

  const resetState = () => {
    setLoading(false);
    setPage(0);
    setSwapInfo(null);
    setSwaps([]);
    setUnconfirmed([]);
    setManualEthAddress('');
  };

  const onInfoUpdated = () => {
    setInfo(store.getStore('info') || {});
  };

  const onError = useCallback(
    (error: Error) => {
      const isWarning = error instanceof Warning;
      const message = error.message;
      const variant = isWarning ? 'warning' : 'error';
      showMessage(message, variant);
      setLoading(false);
    },
    [showMessage]
  );

  const onSwapsFetched = (_swaps: any) => {
    setSwaps(_swaps);
    setLoading(false);
  };

  const onUnconfirmedTransactionsFetched = (_transactions: any) => {
    setUnconfirmed(_transactions);
  };

  const onNext = (address: string) => {
    switch (page) {
      case 0:
        swapToken(address);
        break;
      case 1:
        // TODO this function isn't define anywhere even though it is in the store?
        // finalizeSwap();
        break;
      default:
    }
  };

  const getUnconfirmedTransactions = useCallback(() => {
    if (swapInfo?.uuid) {
      dispatcher.dispatch({
        type: Actions.GET_UNCONFIRMED_TXS,
        content: {
          uuid: swapInfo.uuid
        }
      });
    }
  }, [swapInfo?.uuid]);

  const getSwaps = useCallback(() => {
    if (swapInfo?.uuid) {
      dispatcher.dispatch({
        type: Actions.GET_SWAPS,
        content: {
          uuid: swapInfo.uuid
        }
      });
      setLoading(true);
    }
  }, [swapInfo?.uuid]);

  const swapToken = (address: string) => {
    if (address !== '') {
      dispatcher.dispatch({
        type: Actions.SWAP_TOKEN,
        content: {
          type: swapType,
          address
        }
      });
      setLoading(true);
    }
  };

  const onRefresh = () => {
    if (!isIdle) {
      getUnconfirmedTransactions();
      getSwaps();
    }
  };

  const onTokenSwapped = useCallback(
    (_swapInfo: any) => {
      setSwapInfo(_swapInfo);
      setPage(1);
      setLoading(false);
      getUnconfirmedTransactions();
      getSwaps();
    },
    [getSwaps, getUnconfirmedTransactions]
  );

  useEffect(() => {
    // Check if user is idle
    if (idleReference) {
      idleReference();
    }

    idleReference = checkIdle(5 * 60 * 1000);
    onInfoUpdated();
    store.on(Events.ERROR, onError);
    store.on(Events.FETCHED_INFO, onInfoUpdated);
    store.on(Events.FETCHED_SWAPS, onSwapsFetched);
    store.on(Events.FETCHED_UNCONFIRMED_TXS, onUnconfirmedTransactionsFetched);
    store.on(Events.TOKEN_SWAPPED, onTokenSwapped);
    dispatcher.dispatch({ type: Actions.GET_INFO });

    return () => {
      if (idleReference) {
        idleReference();
      }
      store.removeListener(Events.ERROR, onError);
      store.removeListener(Events.FETCHED_INFO, onInfoUpdated);
      store.removeListener(Events.FETCHED_SWAPS, onSwapsFetched);
      store.removeListener(
        Events.FETCHED_UNCONFIRMED_TXS,
        onUnconfirmedTransactionsFetched
      );
      store.removeListener(Events.TOKEN_SWAPPED, onTokenSwapped);
    };
  }, [onError, onTokenSwapped]);

  return (
    <Grid container spacing={10}>
      {page === 0 && (
        <RenderSelection
          loading={loading}
          swaps={swaps}
          unconfirmed={unconfirmed}
          swapType={swapType}
          setSwapType={setSwapType}
          setManualEthAddress={setManualEthAddress}
          onNext={onNext}
          classes={classes}
        />
      )}
      {swapInfo && page === 1 && (
        <RenderInfo
          loading={loading}
          swaps={swaps}
          manualEthAddress={manualEthAddress}
          unconfirmed={unconfirmed}
          swapType={swapType}
          swapInfo={swapInfo}
          info={info}
          onRefresh={onRefresh}
          resetState={resetState}
          classes={classes}
        />
      )}
    </Grid>
  );
}
