import colors from './colors';
import { PaletteOptions, createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: ['Lato', 'Roboto', 'Open Sans', 'sans-serif'].join(','),
    h6: {
      fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
      fontSize: '0.8rem',
      fontWeight: 600,
      marginBottom: '.5rem',
      lineHeight: 1.45
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-root': {
            fontSize: '13px',
            background: colors.oxenBlack60,
            borderRadius: '8px',
            innerShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.20) inset',
            input: {
              padding: '10px'
            }
          },
          '&.MuiOutlinedInput-notchedOutline': {
            borderRadius: '0px'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root': {
            fontSize: '0.7rem'
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors.black,
          '& .MuiSvgIcon-root': {
            fontSize: '16px',
            background: colors.oxenBlack60,
            color: 'rgba(255, 255, 255, 0)',
            borderRadius: '100%',
            innerShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.20) inset'
          },
          '&.Mui-checked': {
            '& .MuiSvgIcon-root': {
              color: colors.oxenGreen
            }
          },
          '&.Mui-disabled': {}
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '14px'
        }
      }
    }
  },
  palette: {
    mode: 'dark' as any,
    primary: {
      main: colors.chainflipCyan
    },
    secondary: {
      main: colors.lightBlack
    },
    navigation: {
      main: colors.chainflipMagenta
    },
    background: {
      paper: colors.oxenBlack80,
      default: '#008522'
    },
    text: {
      primary: colors.white,
      secondary: colors.chainflipCyan
    }
  } as PaletteOptions
});

export default theme;
