const colors = {
  oxenGreen: '#00F782',
  chainflipCyan: '#00F782',
  chainflipMagenta: '#00F782',
  oxenBlack90: '#0A0A0A',
  oxenBlack80: 'rgba(255, 255, 255, 0.40)',
  oxenBlack60: 'rgba(0,0,0,0.5)',
  oxenBlack50: '#7E7E7E',
  white: '#fff',
  black: '#000',
  gray: '#e1e1e1',
  lightGray: '#fafafa',
  lightBlack: '#6a6a6a',
  darkBlack: '#141414',
  gradient: 'linear-gradient(to bottom, #78be20 0%, #008522 50%, #005f61 100%)'
};

export default colors;
