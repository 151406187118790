import React from 'react';
import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent } from '@mui/material/Select';
import StyledLabel from '../label';
import makeStyles from '@mui/styles/makeStyles';
import theme, { OxenTheme } from 'theme/oxen';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const useStyles = makeStyles((theme: OxenTheme) => ({
  root: {
    verticalAlign: 'bottom',
    minWidth: '100px',
    marginBottom: '12px',
    marginTop: '12px'
  }
}));

type RadioProps = {
  label?: string;
  id?: string;
  value?: string;
  handleChange?: (event: SelectChangeEvent<string>) => void;
  fullWidth?: boolean;
  options?: Array<any>;
  disabled?: boolean;
};

function StyledRadio(props: RadioProps) {
  const { label, value, handleChange, fullWidth, options, disabled } = props;

  const classes = useStyles(theme);

  return (
    <FormControl
      className={classes.root}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {label && <StyledLabel label={label} />}
      <RadioGroup value={value} onChange={handleChange} color={'secondary'}>
        {options
          ? options.map((option: any) => {
              return (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  label={option.description}
                  control={<Radio />}
                />
              );
            })
          : null}
      </RadioGroup>
    </FormControl>
  );
}

export default StyledRadio;
